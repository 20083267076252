import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from "../component/Loading.js";
import { UserContext } from "../UserProvider";
import "./User.css";
import Footer from "../component/Footer";
function User() {
  const [isLoading, setIsLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [username, setUsername] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [choose, setChoose] = useState(2);
  const [math, setMath] = useState([]);
  const [english, setEnglish] = useState([]);
  const [korean, setKorean] = useState([]);
  const [billing, setBilling] = useState();
  const history = useNavigate();
  const types = {
    "1": "풀모",
    "2": "하프",
    "3": "킬러",
    "4": "어삼쉬사",
    "5": "하프",
    "6": "빈순삽",
    "7": "최고난도",
    "8": "시간단축",
    "9": "풀모",
    "10": "매3비",
    "11": "매3문",
    "12": "매3화작",
  };
  const select = {
    m1: "수1",
    m2: "수2",
    cal: "미적",
    prob: "확통",
    vec: "기하",
    media:"언매",
    talk:"화작",
  };
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  useEffect(() => {
    axios
      .get("/getsolved/1")
      .then((response) => {
        setMath(response.data.solved.sort((a, b) => a.id - b.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/getsolved/5")
      .then((response) => {
        setEnglish(response.data.solved.sort((a, b) => a.id - b.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/getsolved/9")
      .then((response) => {
        setKorean(response.data.solved.sort((a, b) => a.id - b.id));
      })
      .catch((error) => {
        console.error("Error fetching getsolved data:", error);
      });
    axios
      .get("/checkbill")
      .then((response) => {
        setBilling(response.data.bill);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching billing data:", error);
      });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch("/changeuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(username),
      });
      if (response.ok) {
        const responseData = await response.json();
        window.alert(responseData.alert);
        setIsLoading(false);
        setClick(false);
        setUser({ ...user, username: username });
      } else {
        history("/error");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const Clearprb = async () => {
    let isClear = window.confirm("정말 초기화 하시겠습니까?");
    if (isClear) {
      try {
        setIsLoading(true);
        const response = await fetch("/clearprb", {
          method: "GET",
        });
        if (response.ok) {
          const data = await response.json();
          setIsLoading(false);
          window.alert(data.alert);
          setMath([]);
          setEnglish([]);
          setKorean([]);
          history("/userinfo");
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };
  const Deleteuser = async () => {
    let isDelete = window.confirm("정말 삭제하시겠습니까?");
    if (isDelete) {
      if (isDelete) {
        try {
          setIsLoading(true);
          const response = await fetch("/deleteuser", {
            method: "POST",
          });
          if (response.ok) {
            const data = await response.json();
            setIsLoading(false);
            window.alert(data.alert);
            setUser({});
            history("/");
          } else {
            history("/error");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }
    }
  };
  const RegenaratePrb = async (prbjson) => {
    try {
      setIsLoading(true);
      const response = await fetch("/regenarateprb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prbjson),
      });
      if (response.ok) {
        if (response.headers.get("Content-Type") === "application/pdf") {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "problems.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setIsLoading(false);
          history("/userinfo");
        } else {
          const data = await response.json();
          window.alert(data.alert);
          setIsLoading(false);
          history("/");
        }
      } else {
        history("/error");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  const deletePrb = async (id, val) => {
    if (window.confirm("정말 삭제하시겠습니까? 삭제 후 복구할 수 없습니다.")) {
      try {
        setIsLoading(true);
        const response = await fetch("/deleteprb", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        });
        if (response.ok) {
          const types = Number(val);
          if (types >= 1 && types <= 4) {
            setMath(math.filter((item) => item.id !== id));
          } else if (types >= 5 && types <= 8) {
            setEnglish(english.filter((item) => item.id !== id));
          } else if (types >= 9 && types <= 12) {
            setKorean(korean.filter((item) => item.id !== id));
          }
          window.alert("삭제 되었습니다.");
          setIsLoading(false);
          history("/userinfo");
        } else {
          history("/error");
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };
  const handleClick = () => {
    setClick(click ? false : true);
    setUsername(user.username);
  };
  const expireCard = () => {
    if (window.confirm("정말 해지하시겠습니까?")) {
      axios
        .get("/expire")
        .then((response) => {
          window.alert(response.data.alert);
          history("/");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="user_ct">
          <div className="nv">
            <Link to="/" className="nv_lg">
              기출조각
            </Link>
          </div>
          {click ? (
            <form className="ui_tct" onSubmit={handleSubmit}>
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">닉네임 : </div>
                  <input onChange={setUsername} name="name" value={username} />
                </div>
                <button
                  className="ui_chg"
                  onClick={() => setClick(click ? false : true)}
                >
                  수정
                </button>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">이메일 : </div>
                <div className="ui_txt">{user.useremail}</div>
              </div>
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">요금제 :</div>
                  <div className="ui_txt">
                    {user.due_date >= currentDate
                      ? `${billing ? "구독권" : "회원권"} 이용중(~${
                          user.due_date
                        })`
                      : "이용 상품 없음"}
                  </div>
                  <Link to="/pricing" className="credit_btn">
                    변경
                  </Link>
                  {billing ? (
                    <div onClick={expireCard} className="credit_btn2">
                      해지
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <button className="ui_chg_fn" type="submit">
                  완료
                </button>
              </div>
            </form>
          ) : (
            <div className="ui_tct">
              <div className="ui_tp">
                <div className="ui_ct">
                  <div className="ui_tt">닉네임 : </div>
                  <div className="ui_txt">{user.username}</div>
                </div>
                <button className="ui_chg" onClick={handleClick}>
                  수정
                </button>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">이메일 : </div>
                <div className="ui_txt">{user.useremail}</div>
              </div>
              <div className="ui_ct">
                <div className="ui_tt">요금제 :</div>
                <div className="ui_txt">
                  {user.due_date >= currentDate
                    ? `${billing ? "구독권" : "회원권"} 이용중(~${
                        user.due_date
                      })`
                    : "이용 상품 없음"}
                </div>
                <Link to="/pricing" className="credit_btn">
                  변경
                </Link>
                {billing ? (
                  <div onClick={expireCard} className="credit_btn2">
                    해지
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div className="usr_ct">
            <div className="usr_bx">
              <div className="bx_txt">내가 푼 문제</div>
              <div className="bx_select">
                <div
                  onClick={() => setChoose(1)}
                  style={{ color: choose === 1 ? "#333" : "#888" }}
                >
                  국어
                </div>
                <div
                  onClick={() => setChoose(2)}
                  style={{ color: choose === 2 ? "#333" : "#888" }}
                >
                  수학
                </div>
                <div
                  onClick={() => setChoose(3)}
                  style={{ color: choose === 3 ? "#333" : "#888" }}
                >
                  영어
                </div>
              </div>
              <div className="bx_prb">
                {choose === 1 &&
                  korean.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined && (
                        <div className="test">
                          <div className="prb_types">{types[key.types]}{(key.types==='9' || key.types==='12')?`(${select[key.select_type]})`:''}</div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div className="solves">
                            {key.problems.length}
                            문제
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {choose === 2 &&
                  math.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined &&
                      key.select_type !== undefined && (
                        <div className="test">
                          <div className="prb_types">
                            {types[key.types]}({select[key.select_type]})
                          </div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div className="solves">
                            {key.problems.length}
                            문제
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                {choose === 3 &&
                  english.map(
                    (key) =>
                      key.types !== undefined &&
                      key.problems !== undefined && (
                        <div className="test">
                          <div className="prb_types">{types[key.types]}</div>
                          <div className="solved_time">{key.solved_time}</div>
                          <div className="solves">
                            {key.types === "5"
                              ? "28"
                              : key.types === "8"
                                ? "15"
                                : key.problems.length}
                            문제
                          </div>
                          <div className="prb_btn_bx">
                            <div
                              className="down_prb"
                              onClick={() => RegenaratePrb(key)}
                            >
                              다운
                              <img
                                src="/img/download.png"
                                width="15px"
                                alt=""
                              />
                            </div>
                            <div
                              className="del_prb"
                              onClick={() => deletePrb(key.id, key.types)}
                            >
                              삭제
                              <img src="/img/remove.png" width="15px" alt="" />
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="btn_cts">
            <div className="prb_reset" onClick={Clearprb}>
              문제 초기화
            </div>
            <div className="user_out" onClick={Deleteuser}>
              회원 탈퇴
            </div>
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default User;
