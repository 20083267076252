import React,{useState} from 'react';
import './Login.css';
import {useNavigate} from 'react-router-dom';
import Loading from '../component/Loading.js';

function Findpwd(){
	const [isLoading, setIsLoading] = useState(false);
	const [isallowed,setIsallowed]=useState(false);
	const [formData, setFormData] = useState({
		userid:undefined,
		username:undefined,
		useremail:undefined,
	});
	const [pwd,setPwd]=useState('');
	const [chkpwd,setChkpwd]=useState('');
	const history = useNavigate();
	
	const findpwdSubmit=async(e)=>{
		e.preventDefault();
		try{
			setIsLoading(true);
			const response = await fetch('/findpwd', {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			const responseData = await response.json();
			if (response.ok) {
				setIsLoading(false);
				if(responseData.isallowed){
					setIsallowed(true);
				}
				else{
					window.alert('오류가 발생했습니다. 다시 시도해 주세요.')
				}
			} else{
				window.alert(responseData.alert);
				history('/')
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};
	const changepwdSubmit=async(e)=>{
		e.preventDefault();
		if(pwd!==chkpwd){
			window.alert('비밀번호를 확인해 주세요.')
		}
		else if(pwd.length<8){
			window.alert('비밀번호는 최소 8자 이상으로 설정해 주세요.')
		}
		else{
			try{
				setIsLoading(true);
				const response = await fetch('/changepwd', {
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({userid:formData.userid,password:pwd}),
				});
				const responseData = await response.json();
				if (response.ok) {
					setIsLoading(false);
					window.alert(responseData.alert);
					history('/');
				} else{
					window.alert(responseData.alert);
					history('/')
				}
			} catch (error) {
				console.error('Network error:', error);
			}
		}
	};
	const handleChange=(e)=>{
		setFormData({ ...formData, [e.target.name]: e.target.value});
	}
	return(
		<div className='lgin_ct'>
			{isLoading? <Loading/>:''}
			{isallowed?
				<form onSubmit={changepwdSubmit} className='lgin_form'>
					<div className='lgin_tt'>비밀번호 변경</div>
					<input type='password' name='password' placeholder='비밀번호' onChange={(e)=>setPwd(e.target.value)} className='lgin_txt' required></input>
					<input type='password' name='password' placeholder='비밀번호 확인' onChange={(e)=>setChkpwd(e.target.value)} className='lgin_txt' required></input>
					{pwd===chkpwd ?'': <div className='alert_txt'>비밀번호를 확인해 주세요.</div>}
					{pwd.length>=8 ?'': <div className='alert_txt'>비밀번호는 최소 8자 이상으로 설정해 주세요.</div>}
					<button type='submit' className='lgin_btn'>확인</button>
				</form>:
				<form onSubmit={findpwdSubmit} className='lgin_form'>
					<div className='lgin_tt'>기출조각</div>
					<input type='text' name='userid' placeholder='아이디' onChange={handleChange} className='lgin_txt' required></input>
					<input type='text' name='username' placeholder='이름' onChange={handleChange} className='lgin_txt' required></input>
					<input type='email' name='useremail' placeholder='이메일' onChange={handleChange} className='lgin_txt' required></input>
					<button type='submit' className='lgin_btn'>비밀번호 변경</button>
				</form>
			}
		</div>
	);
}

export default Findpwd;