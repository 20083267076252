import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});

   useEffect(() => {
		axios.get('/getUser', { withCredentials: true })
		.then(response => {
			setUser(response.data.user);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	}, []);
    return (
        <UserContext.Provider value={{user,setUser}}>
            {children}
        </UserContext.Provider>
    );
};