import React,{useState,useEffect} from 'react';
import { useParams,Link } from 'react-router-dom';
import './Yearly.css';
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import { NotionRenderer } from "react-notion";

function Yearly(){
	const {month}=useParams();
	const [response, setResponse] = useState({});
	const urls={
		'3':'3-cb13e9a97bea45648591b13131f43edf',
		'5':'5-97dfcc1cbe9b4149b6f2188c53c08b61',
		'6':'6-2a56ce7ab1c1400b80378bbc8e1b352d',
		'7':'7-66a4b78d2be345299f949b3519e40cf0',
		'9':'9-62b8461784fa4ae5af9ea8e6717ee4f5',
	}
	useEffect(() => {
    fetch(`https://notion-api.splitbee.io/v1/page/${urls[month]}`)
      .then(res => res.json())
      .then((resJson) => {
        setResponse(resJson);
      });
  })
	const onClick=()=>{
		window.alert('아직 준비중입니다.');
	}
	return(
		<div className='yearly'>
			<div className='tops'>
				<Link to='/' className='logos'>기출조각</Link>
			</div>
			<div className='title_txt'>{month}월 모의고사</div>
			<div className='btn_bxs'>
				<a href={'/prb/'+month} className='btns'>문제</a>
				<a href={'/sol/'+month} className='btns'>해설</a>
				{month==='5'?<div className='btns' onClick={onClick}>손풀이</div>:<a href={'/hand/'+month}  className='btns'>손풀이</a>}
			</div>
			<div className='notion_box'><NotionRenderer blockMap={response}/></div>
		</div>
	)
}

export default Yearly;