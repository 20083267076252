// src/Slideshow.js
import React, { useState, useEffect } from 'react';
import './SlideShow.css'; // 스타일링을 위한 CSS 파일

const Slideshow = ({ children, interval = 3000 }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [fade, setFade] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % React.Children.count(children));
        setFade(true);
      }, 500); // fade out 기간
    }, interval);

    return () => clearInterval(timer);
  }, [children, interval]);
  return (
	  <div className="slideshow">
		 <div className={`slide ${fade ? 'fade-in' : 'fade-out'}`}>
        {React.Children.toArray(children)[currentIndex]}
      </div>
	  </div>
  );
};

export default Slideshow;
