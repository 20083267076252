import React from 'react';
import './Footer.css';

function Footer(){
	return(
		<div className='footer column1'>
			<div className='footer_bx column1'>
				<div className='footer_txt'>©아이디어웍스. All Right Reserved.</div>
				<div className='footer_txt'>사업자 등록 번호 816-19-02647 | 대표 엄선명 </div>
				<div className='footer_txt'>경기도 김포시 돌문로 50-20, 5층 502-17호(사우동, 로얄프라자) | 070-8058-9641</div>
				<div className='footer_txt'>고객센터 supports@gichulzogak.kr</div>
				<div>
					<a href='/service' className='footer_txt'>사이트 이용 약관 | </a>
					<a href='/privacy' className='footer_txt'> 개인정보 처리 방침</a>
				</div>
			</div>
		</div>
	)
}
export default Footer;