import React, { useState } from 'react';
import './Card.css';
import { useNavigate} from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import Loading from './Loading';
const CardInfoForm = ({isOpen,setIsOpen}) => {
	const history=useNavigate();
	const[isLoading,setIsLoading]=useState(false);
  const [cardNo, setCardNo] = useState(['', '', '', '']);
  const [expYear, setExpYear] = useState('');
  const [expMonth, setExpMonth] = useState('');
  const [idNo, setIdNo] = useState('');
  const [cardPw, setCardPw] = useState('');
	const orderId = uuidv4();

  const handleCardNoChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ''); // 숫자만 허용
    if (value.length <= 4) {
      const newCardNo = [...cardNo];
      newCardNo[index] = value;
      setCardNo(newCardNo);

      // 자동으로 다음 필드로 포커스 이동
      if (value.length === 4 && index < 3) {
        document.getElementById(`cardNo${index + 1}`).focus();
      }
		if(value.length===4 && index===3){
			document.getElementById('exp1').focus();
		}
    }
  };

  const handleExpYearChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // 숫자만 허용
    if (value.length <= 2) setExpYear(value);
	  if(value.length===2){
		  document.getElementById('exp2').focus();
	  }
  };

  const handleExpMonthChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // 숫자만 허용
    if (value.length <= 2 && parseInt(value, 10) <= 12) setExpMonth(value);
	  if(value.length===2){
		  document.getElementById('birth').focus();
	  }
  };

  const handleIdNoChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // 숫자만 허용
    if(value.length <= 6) setIdNo(value);
	  if(value.length===6){
		  document.getElementById('pwd').focus();
	  }
  };

  const handleCardPwChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // 숫자만 허용
    if (value.length <= 2) setCardPw(value);
  };
	const handleInputFocus = (e) => {
    e.target.select(); // 입력 필드에 포커스가 들어오면 텍스트 전체 선택
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // 카드 번호 합치기
	  setIsLoading(true);
    const fullCardNo = cardNo.join('');
    axios.post('/billing',{fullCardNo,expYear,expMonth,idNo,cardPw,orderId})
	  .then(response=>{
		window.alert(response.data.alert);
		setIsLoading(false);
		history('/');
	}).catch(error=>{
		console.log(error);
	})
  };
	const refresh=()=>{
		setCardNo(['','','','']);
		setExpYear('');
		setExpMonth('');
		setIdNo('');
		setCardPw('');
		setIsOpen(false);
	}
  return (
	  <div className='card_ct' style={{'display':isOpen?'flex':'none'}}>
		  {isLoading?<Loading/>:
    <form onSubmit={handleSubmit} className='card_bx'>
		<img src='/img/nav_close.png' alt='close' width="25px" className='card_close' onClick={refresh}/>
		<div className='card_title'>구독 결제용 카드 등록</div>
		<img src='/img/card_credit.png' alt='card' width='150px' className='card_img'/>
      <div className='card_ctt'>
        <label className='card_txt'>카드 번호</label>
        <div>
          {cardNo.map((_, index) => (
            <input
              key={index}
              id={`cardNo${index}`}
              type={index === 3 ? 'password' : 'text'}
              value={cardNo[index]}
				onFocus={handleInputFocus}
              onChange={(e) => handleCardNoChange(e, index)}
              placeholder="0000"
              maxLength="4"
				className='card_input'
				required
            />
          ))}
        </div>
      </div>
      <div className='card_ctt'>
        <label className='card_txt'>유효기간(MM/YY)</label>
		  <div>
		<input
          type="text"
			id='exp2'
          value={expMonth}
			onFocus={handleInputFocus}
          onChange={handleExpMonthChange}
          placeholder="MM"
          maxLength="2"
			className='card_input'
			required
        />
        <input
          type="text"
			id='exp1'
          value={expYear}
			onFocus={handleInputFocus}
          onChange={handleExpYearChange}
          placeholder="YY"
          maxLength="2"
			className='card_input'
			required
        />
		</div>
      </div>
      <div className='card_ctt'>
        <label className='card_txt'>생년월일(YYMMDD)</label>
        <input
          type="text"
			id='birth'
          value={idNo}
			onFocus={handleInputFocus}
          onChange={handleIdNoChange}
          placeholder="YYMMDD"
			className='birth_input'
			required
        />
      </div>
      <div className='card_ctt'>
        <label className='card_txt'>카드 비밀번호(앞 2자리)</label>
        <input
          type="password"
			id='pwd'
          value={cardPw}
			onFocus={handleInputFocus}
          onChange={handleCardPwChange}
          placeholder="**"
          maxLength="2"
			className='card_input'
			required
        />
      </div>
      <button type="submit" className='card_submit'>등록하기</button>
    </form>}
	</div>
  );
};

export default CardInfoForm;
