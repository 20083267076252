import React,{useState,useContext} from 'react';
import './Signup.css';
import Loading from '../component/Loading.js';
import { UserContext } from '../UserProvider';
import {useNavigate,Link} from 'react-router-dom';
import axios from 'axios';

function Signup(){
	const history = useNavigate();
	const {setUser} = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [idChk,setIdChk]= useState(false);
	const [emailChk,setEmailChk]=useState(false);
	const [isEmailing,setIsEmailing]=useState(false);
	const [serveEmail,setServeEmail]=useState(null);
	const [cliEmail,setCliEmail]=useState(null);
	const [chkpwd,setChkpwd]=useState();
	const [formData, setFormData] = useState({
		userid:null,
		useremail:null,
		password:null,
	},[]);
	const signupSubmit=async(e)=>{
		e.preventDefault();
		if(formData.password!==chkpwd){
			window.alert('비밀번호를 확인해 주세요.');
		}
		else if(!idChk){
			window.alert('아이디 중복 확인을 해주세요.')
		}
		else if(!emailChk){
			window.alert('이메일 인증이 필요합니다.')
		}
		else{
			try{
				setIsLoading(true);
				const response = await fetch('/signup', {
					method: 'post',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formData),
				});
				const responseData = await response.json();
				if (response.ok) {
					setIsLoading(false);
					window.alert(responseData.alert);
					axios.get('/getUser', { withCredentials: true })
						.then(response => {
						setUser(response.data.user);
						history('/');
					})
					.catch(error => {
						setIsLoading(false);
						console.error('Error fetching user data:', error);
					});
				} else{
					setIsLoading(false);
					window.alert(responseData.alert);
				}
			} catch (error) {
				console.error('Network error:', error);
			}
		}
	};
	const handleIdCheck=()=>{
		axios.post('/checkid',{userid:formData.userid})
		.then(response=>{
			if(response.data.isOk){
				setIdChk(true);
			}
			else{
				window.alert('아이디가 중복됩니다. 다른 아이디를 시도해 주시길 바랍니다.')
			}
		}).catch(error=>{
			window.alert('아이디 확인 중 오류가 발생했습니다. 다시 시도해 주세요.')
		})
	}
	const handleEmailCheck=()=>{
		setIsEmailing(true);
		axios.post('/checkemail',{email:formData.useremail})
		.then(response=>{
			window.alert('메일로 인증 번호가 발송되었습니다. <확인>을 눌러 이메일 인증을 진행해 주세요')
			const data=response.data.email;
			setServeEmail(data);
		}).catch(error=>{
			window.alert('이메일이 중복됩니다. 다른 이메일로 시도해 주세요.');
		})
	}
	const compareEmail=()=>{
		if(cliEmail===null){
			window.alert('이메일 인증 코드를 입력해 주세요.')
		}
		else if(serveEmail===null){
			window.alert('서버 데이터 접속 오류 입니다. 다시 시도해 주세요.')
			setIsEmailing(false);
		}
		else if(serveEmail!==cliEmail){
			window.alert('번호가 다릅니다. 다시 시도해 주세요.')
			setIsEmailing(false);
		}
		else if(serveEmail===cliEmail){
			window.alert('인증되었습니다.')
			setEmailChk(true);
			setIsEmailing(false);
		}
	}
	const handleChange=(e)=>{
		if(e.target.name==='userid' && idChk){
			setIdChk(false);
		}
		setFormData({ ...formData, [e.target.name]: e.target.value});
	}

	const Chkpwd=(e)=>{
		setChkpwd(e.target.value);
	}
	return(
		<div className='lgin_ct1'>
			{isLoading? <Loading/>:''}
			{isEmailing?<div className='lgin_form1'>
				 <div className='lgin_tt'>이메일 인증</div>
				 <input type='text' name='emailchk' className='lgin_txt1' placeholder='6자리 코드' onChange={(e)=>setCliEmail(e.target.value)} required></input>
				 <button onClick={compareEmail} className='lgin_btn1'>인증하기</button>
			 </div>:
			<div>
			<form onSubmit={signupSubmit} className='lgin_form1'>
				<Link to='/' className='lgin_tt'>기출조각</Link>
				<div className='lgin_tct'>
					<label htmlFor='userid' className='lgin_lbl'>아이디</label>
					<input type='text' name='userid' placeholder='아이디'onChange={handleChange} className='lgin_txt1' required></input>
					<button className='ck_btn' onClick={handleIdCheck}>중복 확인</button>
					{idChk?<div className='alert_txt'>아이디 사용 가능</div>:<div className='alert_txt'>아이디 중복을 확인해 주세요.</div>}
				</div>
				<div className='lgin_tct'>
					<label htmlFor='useremail' className='lgin_lbl'>이메일</label>
					<input type='email' name='useremail' placeholder='이메일'onChange={handleChange} value={formData.useremail} className='lgin_txt1' required readOnly={emailChk}></input>
					<button className='ck_btn' onClick={handleEmailCheck}>이메일 인증</button>
					{emailChk?'':<div className='alert_txt'>이메일 인증이 필요합니다.</div>}
				</div>
				<div className='lgin_tct'>
					<label htmlFor='password' className='lgin_lbl'>비밀번호</label>
					<input type='password' name='password' placeholder='비밀번호' onChange={handleChange} className='lgin_txt1' required></input>
				</div>
				<div className='lgin_tct'>
					<label htmlFor='password_test' className='lgin_lbl'>비밀번호 확인</label>
					<input type='password' name='password_test'value={chkpwd} onChange={Chkpwd} placeholder='비밀번호 확인'className='lgin_txt1' required></input>
					{formData.password===chkpwd ?'': <div className='alert_txt'>비밀번호를 확인해 주세요.</div>}
				</div>
				<button type='submit' className='lgin_btn1'>회원가입</button>
			</form>
			<div className='social_signup'>
				<div className='scbx_txt'>또는</div>
				<div className='scbx_ct'>
					<a href='/login/google'><div className='sc_bx google'></div></a>
				</div>
			</div>
		</div>}
		</div>
	);
}

export default Signup;