import React,{useState,useEffect,useContext} from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import {Link} from 'react-router-dom';
import Loading from '../component/Loading.js';
import Pagination from "react-js-pagination";
import Nav from '../component/Nav.js';
import './Board.css';

function Board(){
	const { type } = useParams();
	const {user} = useContext(UserContext);
	const history = useNavigate();
	const types={
		'1':'공지사항 게시판',
		'2':'오류 제보 게시판',
		'3':'질문 게시판',
		'4':'칼럼 게시판'
	}
	const [isLoading, setIsLoading] = useState(false);
	const [page,setPage]=useState(1);
	const [tot,setTot]=useState({});
	const [posts,setPosts]=useState([]);
	useEffect(()=>{
		if(!(Number(type)>=1 && Number(type)<=4)){
			history('/error');
		}
		setIsLoading(false);
		setPage(1);
		axios.get(`/posts/paging/${type}`, { withCredentials: true })
		.then(response => {
			setIsLoading(false);
			setTot(response.data);
			console.log(tot);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	},[type]);
	useEffect(()=>{
		setIsLoading(false);
		axios.get(`/posts/paging/${type}/${page}`, { withCredentials: true })
		.then(response => {
			setIsLoading(false);
			setPosts(response.data);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	},[page,type]);
	return(
		<div>
			{isLoading?<Loading/>:
			<div>
				 <Nav/>
				 <div className='brd'>
					 <div className='brd_ct'>
						 <div className='brd_tt'>게시판 > {types[type]}</div>
						 {type==='4'?<div className="yearly">
                <div className="column dss">
                  <div className="scr_b">
                    <div className="xscroll">
                      <Link className="prb_box" to="/exam/3">
                        <div className="yearly_day">3/28</div>
                        <div className="yearly_title">3월 학평</div>
                      </Link>
                      <Link className="prb_box" to="/exam/5">
                        <div className="yearly_day">5/8</div>
                        <div className="yearly_title">5월 학평</div>
                      </Link>
                      <Link className="prb_box" to="/exam/6">
                        <div className="yearly_day">6/4</div>
                        <div className="yearly_title">6월 모고</div>
                      </Link>
                      <Link className="prb_box" to="/exam/7">
                        <div className="yearly_day">7/11</div>
                        <div className="yearly_title">7월 학평</div>
                      </Link>
											<Link className="prb_box" to="/exam/9">
                        <div className="yearly_day">9/4</div>
                        <div className="yearly_title">9월 모고</div>
                        <div className="new_txt">new!!</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>:''}
						 <div className='brdtb_ct'>
							 <table className='brd_tb'>
								 <thead>
								 	<tr>
										<th scope='col'>번호</th>
								 		<th scope='col'>제목</th>
										<th scope='col'>작성자</th>
								 		<th scope='col'>작성일자</th>
								 	</tr>
								 </thead>
								 <tbody>
						 {posts.map((item,index)=>
							<tr onClick={()=>history(`/post/${item.id}`)} className='brd_lst'>
								<td>{item.id}</td>
								<td>{item.title}</td>
								<td>{item.user_name}</td>
								<td>{item.updated_at}</td>
							</tr>
						)}
								</tbody>
							</table>
						 <Pagination
							 activePage={page}
							 itemsCountPerPage={10}
							 totalItemsCount={tot.len}
							 pageRangeDisplayed={5}
							 onChange={setPage}
							 />
							 {user.isLogin && ((type==='1' && user.userid==='admin') || type!=='1')?
							 <div className='wrt'>
								 <button className='wrt_btn' onClick={()=>history(`/posting/${type}`)}>글쓰기</button>
							 </div>:''}
							 <div style={{'height':'300px'}}></div>
					 </div>
					 </div>
				</div>
			 </div>}
		</div>
	);
}

export default Board;