import React,{useState} from 'react';
import './Dropdown.css';

function Dropdown(props){
	const [isClick,setIsClick]=useState(false);
	function dpnClick(){
		setIsClick(isClick?false:true);
	}
	function keyClick(key){
		props.valChange({...props.data,[props.name]:key});
		setIsClick(false);
	}
	return(
		<div className='dpn'>
			<div className='cur' onClick={dpnClick}>{props.val}</div>
			<div className='ico' onClick={dpnClick}></div>
			<div className='cur_dpn' style={{display:isClick?'block':'None'}}>
				{props.items.map((year) => (
					<div key={year} className='dpn_key' onClick={()=>keyClick(year)}>{year}</div>
				))}
			</div>
		</div>
	);
}

export default Dropdown;