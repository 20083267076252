import React,{useState,useContext} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Loading from '../component/Loading.js';
import { UserContext } from '../UserProvider';
import './Done.css';

function Done(props){
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		star:4,
		description:'',
	});
	const {user} = useContext(UserContext);
	const history = useNavigate();
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const response = await fetch('/faqs', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			if (response.ok) {
				const responseData = await response.json();
				window.alert(responseData.alert);
				setIsLoading(false);
				history('/');
			} else{
				history('/error')
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};
	const handleChange=(names,values)=>{
		setFormData({ ...formData, [names]: values});
	}
	return(
		<div>
			{isLoading?<Loading/>:
		<div className='dn column3'>
			<div className='header1 row3'>
				<Link to='/'className='logo3'>기출조각</Link>
			</div>
			<div className='column3'>
				<div className='dn_txt'>Done!</div>
				{user.isLogin? <div className='caution1'>해설은 <Link to='/userinfo' className='mypage'>마이페이지</Link>에서 확인할 수 있습니다.</div>:""}
			</div>
			<form onSubmit={handleSubmit} className='fq column3'>
				<div className='row3 str'>
					<img src={"/icon/star_"+(formData.star<1?"before":"after")+".svg"} alt='' style={{width:'30px'}} onClick={()=>handleChange('star',1)}/>
					<img src={"/icon/star_"+(formData.star<2?"before":"after")+".svg"} alt='' style={{width:'30px'}} onClick={()=>handleChange('star',2)}/>
					<img src={"/icon/star_"+(formData.star<3?"before":"after")+".svg"} alt='' style={{width:'30px'}} onClick={()=>handleChange('star',3)}/>
					<img src={"/icon/star_"+(formData.star<4?"before":"after")+".svg"} alt='' style={{width:'30px'}} onClick={()=>handleChange('star',4)}/>
					<img src={"/icon/star_"+(formData.star<5?"before":"after")+".svg"} alt='' style={{width:'30px'}} onClick={()=>handleChange('star',5)}/>
				</div>
				<textarea placeholder='피드백 해주시면 감사하겠습니다:)' id='description' onChange={(e)=>handleChange('description',e.target.value)}required/>
				<button type='submit' className='sbm'>제출</button>
			</form>
			<div className='moveto'><Link to='/' className='mv_txt'>새로운 문제 만들러가기 →</Link></div>
			<div className='gaps2'></div>
		</div>
			 }
		</div>
	);
}

export default Done;