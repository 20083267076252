import React from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Main from './pages/Main';
import Problem from './pages/Problem';
import Done from './pages/Done';
import ScrollTop from './component/ScrollTop';
import Login from './pages/Login';
import Error from './pages/Error';
import Signup from './pages/Signup';
import Findid from './pages/Findid';
import Findpwd from './pages/Findpwd';
import User from './pages/User';
import Yearly from './pages/Yearly';
import Board from './pages/Board';
import Post from './pages/Post';
import Posting from './pages/Posting';
import Chposting from './pages/Chposting';
import Pricing from './pages/Pricing';
function App(){
	return(
		<BrowserRouter>
			<ScrollTop />
			<Routes>
				<Route path='/problem/:id' element={<Problem/>}></Route>
				<Route path='/board/:type' element={<Board/>}></Route>
				<Route path='/post/:id' element={<Post/>}></Route>
				<Route path='/change/:id' element={<Chposting/>}></Route>
				<Route path='/posting/:id' element={<Posting/>}></Route>
				<Route path='/userinfo' element={<User/>}></Route>
				<Route path='/done' element={<Done/>}></Route>
				<Route path='/pricing' element={<Pricing/>}></Route>
				<Route path='/login' element={<Login/>}></Route>
				<Route path='/signup' element={<Signup/>}></Route>
				<Route path='/find/id' element={<Findid/>}></Route>
				<Route path='/find/password' element={<Findpwd/>}></Route>
				<Route path='/exam/:month' element={<Yearly/>}></Route>
				<Route path='/error' element={<Error/>}></Route>
				<Route path='/:id' element={<Main/>}></Route>
				<Route path='/' element={<Main/>}></Route>
				<Route path='*' element={<Error/>}></Route>
			</Routes>
		</BrowserRouter>
	)
}

export default App;
