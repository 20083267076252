import React from 'react';
import {Link} from 'react-router-dom';
function Error(){
	return(
		<div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center', gap:'30px',flexDirection:'column'}}>
			<div style={{fontFamily:'Black han sans', fontSize:'60px'}}>404 PAGE ERROR</div>
			<div style={{fontFamily:'NanumMyeongjo'}}>페이지를 찾을 수 없습니다. 다시 시도하거나 문의 부탁드립니다.</div>
			<Link to='/'>홈으로</Link>
		</div>
	)
}

export default Error;