import React, { useState, useContext, useEffect } from "react";
import "./Popup.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Loading from "./Loading";
const Popup = ({ isOpen, setIsOpen, price, goodsName }) => {
  const { user } = useContext(UserContext);
  const [clientKey, setClientKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [select, setSelect] = useState("cardAndEasyPay");
  const orderId = uuidv4();
  const history = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    axios
      .get("/clientkey", { withCredentials: true })
      .then((response) => {
        setClientKey(response.data.clientKey);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  const testgo = () => {
    if (user.isLogin) {
      if (typeof window !== "undefined") {
        axios
          .post("/verify", { orderId: orderId, amount: price })
          .then((response) => {
            const payElem = window;
            const { AUTHNICE } = payElem;
            AUTHNICE.requestPay({
              clientId: clientKey,
              method: select,
              orderId: orderId,
              amount: price,
              goodsName: goodsName,
              returnUrl: window.location.origin + "/serverAuth",
              fnError: function(result) {
                alert(result.errorMsg);
              },
              skinType: "gray",
            });
          })
          .catch((error) => {
            window.alert(
              "결제창 로드 중 오류가 발생했습니다. 다시 시도해 주세요."
            );
          });
      }
    } else {
      window.alert("로그인 후 이용해 주세요.");
      history("/login");
    }
  };
  const refresh = () => {
    setIsOpen(false);
  };
  return (
    <div className="pop_ct" style={{ display: isOpen ? "flex" : "none" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="pop_bx">
          <img
            src="/img/nav_close.png"
            alt="close"
            width="25px"
            className="pop_close"
            onClick={refresh}
          />
          <div className="pop_title">결제 수단 선택</div>
          <div className="pop_ctt pop_row">
            <div>제품명 : {goodsName}</div>
            <div>가격 : {price}원</div>
          </div>
          <div
            className="pop_ctt"
            style={{ color: "#555", textAlign: "center" }}
          >
            본 제품은 무제한 이용권으로 기한 내 자유롭게 모든 서비스를 이용하실
            수 있습니다.
          </div>
          <div className="pop_ctt pay_bx">
            <div
              className={select === "cardAndEasyPay" ? "chosen_btn" : "pay_btn"}
              onClick={() => setSelect("cardAndEasyPay")}
            >
              카드
            </div>
            <div
              className={select === "bank" ? "chosen_btn" : "pay_btn"}
              onClick={() => setSelect("bank")}
            >
              계좌이체
            </div>
            <div
              className={select === "naverpayCard" ? "chosen_btn" : "pay_btn"}
              onClick={() => setSelect("naverpayCard")}
            >
              네이버페이
            </div>
            <div
              className={select === "kakaopay" ? "chosen_btn" : "pay_btn"}
              onClick={() => setSelect("kakaopay")}
            >
              카카오페이
            </div>
            <div
              className={select === "samsungpayCard" ? "chosen_btn" : "pay_btn"}
              onClick={() => setSelect("samsungpayCard")}
            >
              삼성페이
            </div>
          </div>
          <button type="button" className="card_submit" onClick={testgo}>
            결제하기
          </button>
        </div>
      )}
    </div>
  );
};

export default Popup;
