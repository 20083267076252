import React,{useState,useContext} from 'react';
import {useNavigate,Link} from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import { UserContext } from '../UserProvider';
import Loading from '../component/Loading.js';

function Login(){
	const {setUser} = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		userid:undefined,
		password:undefined,
	});
	const history = useNavigate();
	const loginSubmit=async(e)=>{
		e.preventDefault();
		try{
			setIsLoading(true);
			const response = await fetch('/login', {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			});
			const responseData = await response.json();
			if (response.ok) {
				axios.get('/getUser', { withCredentials: true })
					.then(res => {
					setUser(res.data.user);
				})
					.catch(error => {
					console.error('Error fetching user data:', error);
				});
				setIsLoading(false);
				window.alert(responseData.alert);

				history('/');
			} else{
				window.alert(responseData.alert);
				history('/')
			}
		} catch (error) {
			console.error('Network error:', error);
		}
	};
	const handleChange=(e)=>{
		setFormData({ ...formData, [e.target.name]: e.target.value});
	}
	return(
		<div className='lgin_ct'>
				{isLoading? <Loading/>:''}
			<form onSubmit={loginSubmit} className='lgin_form'>
				<Link to='/' className='lgin_tt'>기출조각</Link>
				<input type='text' name='userid' placeholder='아이디' onChange={handleChange} className='lgin_txt'></input>
				<input type='password' name='password' placeholder='비밀번호'onChange={handleChange}className='lgin_txt'></input>
				<button type='submit' className='lgin_btn'>로그인</button>
				<div className='opt'>
					<a href='/signup'className='opt_txt'>회원가입</a>
					<div className='opt_find'>
						<a href='/find/id'className='opt_txt'>아이디 찾기</a>
						<a href='/find/password'className='opt_txt'>비밀번호 찾기</a>
					</div>
				</div>
			</form>
			<div className='social_login'>
				<div className='scbx_txt1'>또는</div>
				<div className='scbx_ct1'>
					<a href='/login/google'><div className='sc_bx1 google'></div></a>
				</div>
			</div>
		</div>
	);
}

export default Login;