import React,{useState,useEffect,useContext} from 'react';
import ReactQuill from "react-quill";
import {Link,useNavigate,useParams} from 'react-router-dom';
import { UserContext } from '../UserProvider';
import axios from 'axios';
import Loading from '../component/Loading.js';
import Nav from '../component/Nav.js';
import './Posting.css';
import 'react-quill/dist/quill.snow.css';

function Chposting(){
	const {id}=useParams();
	const history = useNavigate();
	const {user} = useContext(UserContext);
	const [content, setContent] = useState(null);
	const [title,setTitle]=useState(null)
	const [isLoading, setIsLoading] = useState(true);
	const toolbarOptions = [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		[{ 'font': [] }],
		['bold', 'italic', 'underline', 'strike'],
		['link', 'image'],
		[{ 'list': 'ordered'}, { 'list': 'bullet' }],
		[{ 'script': 'sub'}, { 'script': 'super' }],      // text direction
		[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
		[{ 'align': [] }],
	];
	const modules={toolbar:toolbarOptions};
	useEffect(()=>{
		setIsLoading(true);
		axios.get(`/posts/${id}`, { withCredentials: true })
		.then(response => {
			setTitle(response.data.title);
			setContent(response.data.content);
			setIsLoading(false);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	},[]);
	const changeposts=()=>{
		setIsLoading(true);
		axios.put(`/posts/${id}`,{user_id:user.userid,user_name:user.username,title:title,content:content}, { withCredentials: true})
		.then(response => {
			window.alert(response.data.alert);
			setIsLoading(false);
			history(`/post/${id}`);
		})
		.catch(error => {
			console.error('Error fetching user data:', error);
		});
	}
	return(
		<div>
			{isLoading?<Loading/>:
			<div>
				 <Nav/>
				 <div className='brd'>
					 <div className='brd_ct'>
						 <div className='header2 row2'>
							<Link to='/'className='logo2'>기출조각</Link>
						 </div>
						 <div className='txt_tt'>게시물 수정</div>
						 <form onSubmit={changeposts}>
						 	<div className='tt_cts'>제목 : <input placeholder='제목을 입력하세요' onChange={(e)=>setTitle(e.target.value)} value={title} className='tt_input' required/></div>
						 	<ReactQuill modules={modules}className='txt_inputs' theme="snow" value={content} onChange={setContent} placeholder='내용을 입력하세요'/>
						 	<div className='edits_tt'><button className='txt_btns' type='submit'>등록</button></div>
						</form>
					 </div>
				</div>
			 </div>}
		</div>
	);
}

export default Chposting;