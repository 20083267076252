// src/CheckboxGroup.js

import React from "react";
import "./CheckboxGroup.css";

const CheckboxGroup = ({ checkedItems, setCheckedItems, nameList }) => {
  const handleChange = (event) => {
    const { name, checked } = event.target;

    if (name === "all") {
      const updatedCheckedItems = Object.fromEntries(
        Object.entries(checkedItems).map(([key]) => [key, checked])
      );
      setCheckedItems(updatedCheckedItems);
    } else {
      setCheckedItems({
        ...checkedItems,
        [name]: checked,
      });
    }
  };

  return (
    <div className="chbx_ct">
      {Object.entries(checkedItems).map(([key, value]) => (
        <label key={key} className="chbx_txt">
          <input
            type="checkbox"
            name={key}
            className="chbx"
            checked={value}
            onChange={handleChange}
          />
          {nameList[key]}
        </label>
      ))}
    </div>
  );
};

export default CheckboxGroup;
